export const environment = {
  baseSocialEventWebUrl:"https://event.dev.superjsbros.com",
  firebase: {
    projectId: 'social-event-dfcb7',
    appId: '1:822976295439:web:26061fd0a12fdaacd8e97a',
    databaseURL: 'https://social-event-dfcb7-default-rtdb.firebaseio.com',
    storageBucket: 'social-event-dfcb7.appspot.com',
    apiKey: 'AIzaSyD98brW5iUMiAM-zWbQLyFw5CFvklyMFtw',
    authDomain: 'social-event-dfcb7.firebaseapp.com',
    messagingSenderId: '822976295439',
    measurementId: 'G-PPXPPMKJ1F',
  },
};