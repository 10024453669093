import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SafeUrl } from "@angular/platform-browser";
import { environment } from 'src/environments/environment';
import { FirestoreService } from 'src/app/services/firestore.service';
import { formatDate } from '@angular/common';
import { take, catchError, tap } from 'rxjs/operators';
import { IFileInfo } from '../file-selector/file-selector.component';
import { StorageService } from 'src/app/services/storage.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public userAppLink = '';
  public hostName = '';
  public qrCodeSrc: SafeUrl = '';
  public startEventDate?:string; // Initialize with null
  public endEventDate?:string; // Initialize with null
  public hostPhoto: IFileInfo[]=[];
  public showUpload = true;
  private accessCode?:string;
  constructor(
    private authenticationService: AuthenticationService,
    private firestoreService: FirestoreService, 
    private storageService: StorageService
  ) {}

  public ngOnInit(): void {
    this.accessCode = this.authenticationService.getEventCode();
    this.userAppLink = environment.baseSocialEventWebUrl + `/${this.accessCode}/drop`;
    if(this.accessCode){
      this.firestoreService.getHostData(this.accessCode).pipe(
        take(1),
        catchError(err => {
          console.log(err);
          return of(null);
        })
      ).subscribe((data)=>{
        this.hostName = data || '';
      });

      this.firestoreService.getEventDates(this.accessCode).pipe(
        take(1),
        catchError(err => {
          console.log(err);
          return of(null);
        })
      ).subscribe((data)=>{
        if(data?.startDate && data?.endDate){
          this.startEventDate = formatDate(data.startDate, 'yyyy-MM-ddThh:mm:ss',"en-CA") ;
          this.endEventDate = formatDate(data.endDate, 'yyyy-MM-ddThh:mm:ss',"en-CA")
        }
      });

      this.storageService.getBlobHostPhoto(this.accessCode).pipe(
        take(1),
        catchError(err => {
          console.log(err);
          return of(null);
        })
      ).subscribe((blob)=>{
        if(blob){
          this.hostPhoto = [{
            file: new File([blob], this.storageService.hostImageName, { type: blob.type}),
            blobUrl: URL.createObjectURL(blob),
          }]
        }
      });
    }
  }

  public onDateChange(): void {
    if (this.startEventDate && this.endEventDate) {
      const accessCode = this.authenticationService.getEventCode();
      
      const localStartDate = new Date(Date.parse(this.startEventDate));
      const localEndDate = new Date(Date.parse(this.endEventDate));
      
      this.firestoreService.updateEventDates(accessCode!, localStartDate, localEndDate).pipe(
        take(1),
        catchError(err => this.handleError(err))
      ).subscribe(()=>this.showSuccessAlert('Event dates updated successfully'));
    } else {
      console.warn('Scheduled event date is not yet set.');
    }
  }

  public onHostNameChange(): void {
    const accessCode = this.authenticationService.getEventCode();
    this.firestoreService.updateHost(accessCode!, this.hostName).pipe(
      take(1),
      catchError(err => this.handleError(err))
    ).subscribe(() => this.showSuccessAlert('Host name updated successfully'));
  }

  public onChangeURL(url: SafeUrl) {
    this.qrCodeSrc = url;
  }
  
  public copyToClipboard(link: string): void {
    navigator.clipboard.writeText(link).then(() => {
      console.log('Link copied to clipboard');
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  }

  public updateStorageHostPhoto(file: File|null){
    if(file){
      this.storageService.addHostPhotoToFirestore(this.accessCode!, file).pipe(
        take(1),
        catchError(err => this.handleError(err))
      ).subscribe(() => this.showSuccessAlert('Host photo added successfully'));
    }
    if(file === null){
      this.storageService.deleteHostPhotoFromFirestore(this.accessCode!).pipe(
        take(1),
        catchError(err => this.handleError(err))
      ).subscribe(()=>this.showSuccessAlert('Host photo deleted successfully'));
    }
  }

  public setUploadUrl() {
    this.showUpload = true;
    this.userAppLink = environment.baseSocialEventWebUrl + `/${this.accessCode}/drop`;
  }
  public setCameraUrl() {
    this.showUpload = false;
    this.userAppLink = environment.baseSocialEventWebUrl + `/${this.accessCode}/snap`;
  }
  private handleError(err: any) {
    alert(`An error occurred while trying to update item`);
    return of(null);
  }

  private showSuccessAlert(message: string) {
    console.log(message);
  }
}