import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  public form!: FormGroup;
  public isLoggingIn = false;
  public submitted = false;

  constructor(
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private router: Router
  ) { }

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }

  public login() {
    this.submitted = true;
    
    if (this.form.invalid) {
      return;
    }

    this.isLoggingIn = true;

    this.authenticationService.signIn({
      email:  String(this.form.value.email).trim().toLowerCase() + '@superjsbros.com',
      password:  String(this.form.value.password).trim()
    }).subscribe({
      next: () => this.router.navigate(['home']),
      error: (error: { message: any; }) => {
        this.isLoggingIn = false;
        window.alert(error.message)
      }
    });
  }

  public showError(controlName: string, errorName: string): boolean {
    const control = this.form.get(controlName);
    return !!control?.dirty && control?.hasError(errorName);
  }
}
