import { Injectable } from '@angular/core';
import { Auth,getAuth,onAuthStateChanged,signInWithEmailAndPassword, signOut } from '@angular/fire/auth';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private auth: Auth
  ) { }

  public signIn(params: SignIn): Observable<any> {
    return from(signInWithEmailAndPassword(this.auth,
      params.email, params.password
    ));
  }

  public signOut(){
    return from(signOut(this.auth))
  }

  public getUser(){
    return getAuth().currentUser
  }

  getEventCode(){
    return getAuth().currentUser?.email?.split('@')[0];
  }
}

type SignIn = {
  email: string;
  password: string;
}

type FirebaseError = {
  code: string;
  message: string
};