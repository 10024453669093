import { Injectable } from '@angular/core';
import { Storage, ref, uploadBytes, getDownloadURL, list, ListResult, getMetadata, FullMetadata, getBlob, getStream, UploadResult, deleteObject } from "@angular/fire/storage";
import { Observable, forkJoin, from, map, mergeMap, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  public hostImageName:string = 'host_img';
  private hostImage?:Blob;
  constructor(private storage:Storage) {}

  public getDownloadUrlsAndMetadata(directory: string): Observable<{ files: { url: string, metadata: FullMetadata }[], nextPageToken?: string, totalCount:number }> {
    const storageRef = ref(this.storage, directory);

    return from(list(storageRef)).pipe(
      mergeMap((res: ListResult) => {
        const fileObservables = res.items.map(item => 
          forkJoin({
            url: from(getDownloadURL(item)),
            metadata: from(getMetadata(item))
          })
        );
        return fileObservables.length ? 
          forkJoin(fileObservables).pipe(
            map(files => ({ files, nextPageToken: res.nextPageToken, totalCount:res.items.length }))
          ) :
          of({ files: [], nextPageToken: undefined, totalCount:0 });
      })
    );
  }

  public getPaginatedDownloadUrlsAndMetadata(directory: string, maxResults: number, pageToken?: string): Observable<{ files: { url: string, metadata: FullMetadata }[], nextPageToken?: string, totalCount:number }> {
    const storageRef = ref(this.storage, directory);
    const listOptions = { maxResults, pageToken };

    return from(list(storageRef, listOptions)).pipe(
      mergeMap((res: ListResult) => {
        const fileObservables = res.items.map(item => 
          forkJoin({
            url: from(getDownloadURL(item)),
            metadata: from(getMetadata(item))
          })
        );
        return fileObservables.length ? 
          forkJoin(fileObservables).pipe(
            map(files => ({ files, nextPageToken: res.nextPageToken, totalCount:res.items.length }))
          ) :
          of({ files: [], nextPageToken: undefined, totalCount:0 });
      })
    );
  }

  public getBlob(directory: string){
    const storageRef = ref(this.storage, directory);
    return from(getBlob(storageRef))
  }

  public getBlobHostPhoto(accessCode: string){
    if(this.hostImage)
      return of(this.hostImage);
    const storageRef = ref(this.storage, `${accessCode}/host-image/${this.hostImageName}`);
    return from(getBlob(storageRef)).pipe(tap((blob)=>{this.hostImage = blob}));
  }

  public addHostPhotoToFirestore(
    accessCode: string,
    blob: Blob
  ): Observable<UploadResult> {
    const storageRef = ref(
      this.storage,
      `${accessCode}/host-image/${this.hostImageName}`
    );

    // 'file' comes from the Blob or File API
    return from(
      uploadBytes(storageRef, blob, {
        customMetadata: {
          accessCode: accessCode,
        },
      })
    ).pipe(tap(()=>{this.hostImage = blob}));
  }

  public deleteHostPhotoFromFirestore(accessCode: string): Observable<void> {
    const storageRef = ref(this.storage, `${accessCode}/host-image/${this.hostImageName}`);
    return from(deleteObject(storageRef)).pipe(tap(()=>{this.hostImage = undefined}));
  }

  public deleteFile(path: string): Observable<void> {
    const storageRef = ref(this.storage, path);
    return from(deleteObject(storageRef)).pipe();
  }
}

