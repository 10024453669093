// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-button {
  width: 300px;
  height: 100px;
  font-size: 24px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem; /* Adjust as needed */
  padding-right: 1rem; /* Adjust as needed */
}

.navbar .navbar-text {
  color: white; /* This will make the text color white */
}

.navbar-brand {
  cursor: pointer;
}

.dropdown {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  display: block;
  border: none;
}

.dropdown-item:hover {
  background-color: #343a40; /* Darker shade of black for hover effect */
}

.custom-container {
  display: flex;
  justify-content: flex-end;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
