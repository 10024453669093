
// app.component.ts
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { RealTimeDbService } from './services/realtimedb.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {


}
