import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileDropEvent } from 'src/app/directives/file-drop.directive';

export interface IFileInfo {
  file?: File;
  blobUrl: string;
}

@Component({
  selector: 'app-file-selector',
  templateUrl: './file-selector.component.html',
  styleUrls: ['./file-selector.component.css'],
  host: {
    class: ' d-inline-block'
  },
})
export class FileSelectorComponent {
  @Input() public fileInfoList: IFileInfo[] = [];
  @Input() public acceptedMIMETypes: string[] = ['image/jpeg', 'image/png'];
  @Output() public fileChanged: EventEmitter<File|null> = new EventEmitter<File|null>();
  public invalidFileType: boolean = false;
  public isFileOver: boolean = false;

  constructor() {}

  public writeValue(obj: any): void {
      this.fileInfoList.push(obj);
  }

  public onFileOver(event: FileDropEvent): void {
    if (event.fileOver !== undefined) {
      this.isFileOver = event.fileOver;
    }
  }

  public onFileDrop(event: FileDropEvent): void {
    const file = event.file;
    if (file) {
      this.invalidFileType = false;
      if (this.acceptedMIMETypes.length > 0 && file && this.acceptedMIMETypes.indexOf(file.type) < 0) {
        this.invalidFileType = true;
        return;
      }

      this.addFile(file);
    }
  }

  public uploadFile(event: Event): void {
    const file = (event?.target as HTMLInputElement)?.files?.[0];
    if (file) {
      this.invalidFileType = false;
      if (this.acceptedMIMETypes.length > 0 && file && this.acceptedMIMETypes.indexOf(file.type) < 0) {
        this.invalidFileType = true;
        return;
      }
      this.addFile(file);      
    }
  }
  public removeSelectedFile(removeFileInfo: any): void {
    this.fileInfoList = this.fileInfoList.filter(
      fileInfo => fileInfo && fileInfo.file && fileInfo.file.name !== removeFileInfo.file.name
    );
    this.fileChanged.emit(null);
  }
  
  public openUrl(url: string): void {
    const newWindow = window.open("", "_blank");
    const img = new Image();
    img.src = url;
    newWindow?.document.body.appendChild(img);
  };

  private addFile(file: File) {
    const blob = new Blob([file]);
    const blobUrl = URL.createObjectURL(blob);
    const addFileInfo = { file, blobUrl };
    this.fileInfoList = [addFileInfo];
    this.fileChanged.emit(file);
  }

 
}