import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

export class FileDropEvent extends Event {
  fileOver?: boolean;
  file?: File;

  constructor(typeArg: string, eventInitDict?: EventInit, fileOver?: boolean, file?: File) {
    super(typeArg, eventInitDict);
    this.fileOver = fileOver;
    this.file = file;
  }
}

@Directive({ selector: '[appFileDrop]' })
export class FileDropDirective {
  @Output() public fileOver: EventEmitter<FileDropEvent> = new EventEmitter<FileDropEvent>();
  @Output() public fileDrop: EventEmitter<FileDropEvent> = new EventEmitter<FileDropEvent>();

  @HostListener('dragover', ['$event'])
  public onDragOver(event: Event): void {
    this.preventAndStop(event);
    this.fileOver.emit(new FileDropEvent('dragover', {}, true));
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(event: Event): void {
    this.preventAndStop(event);
    this.fileOver.emit(new FileDropEvent('dragleave', {}, false));
  }

  @HostListener('drop', ['$event'])
  public onDrop(event: Event): void {
    this.preventAndStop(event);
    const transfer = this.getDataTransfer(event);
    this.fileDrop.emit(new FileDropEvent('drop', {}, false, transfer.files[0]));
  }

  private preventAndStop(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  private getDataTransfer(event: any | any): DataTransfer {
    return event.dataTransfer ? event.dataTransfer : event.originalEvent.dataTransfer;
  }
}